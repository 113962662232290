@media screen and (max-width: 1400px) {
    #image-ai{
        display: none;
    }
    #ai-1{
        border-right: 1px solid var(--border-light);
    }
    .ai-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1200px) {
    .flex-row-to-column{
        align-items: flex-start;
        flex-direction: column;
    }
    .container{
        padding: 6vh 6vw;
    }
    .slider-wrap{
        margin-left: 6vw;
    }
    .separator{
        margin: 0 6vw;
    }
    nav{
        padding: 1.325rem 6vw;
    }
}

@media screen and (max-width: 992px) {
    a{
        font-size: 18px;
    }
    h2{
        font-size: 32px;
    }
    h3{
        font-size: 20px;
    }
    h4{
        font-size: 18px;
    }
    p{
        font-size: 18px;
    }
    .title-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .star-position img{
        width: calc(55px + 6vw);
        height: calc(55px + 6vw);
    }
    .get-in-touch{
        flex-direction: column-reverse;
    }
    .get-in-touch img{
        width: 120px;
        height: 120px;
    }
    .title-section .inner{
        flex-direction: column;
        gap: 0;
    }
    .to-about-section .merger{
        flex-direction: column;
        align-items: flex-start;
    }
    .services-list .item img{
        width: 40px;
        height: 40px;
    }
    .h-item img{
        width: 420px;
    }
    .slider-wrap{
        max-height: 422px;
    }
    #review-1{
        width: 30px;
        height: 26px;
    }
    .star-dark{
        width: 42px;
        height: 42px;
    }
    .case-1 a{
        font-size: 16px;
    }
    .stats-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .large-stats-item{
        display: none;
    }
    .two-cell{
        grid-column: span 1;
    }
    .no-border{
        border-left: 0;
    }
    .stats-desc{
        display: none;
    }
    .clients-flexbox p{
        margin-bottom: 0;
    }
    .footer-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .footer-container{
        width: 100%;
    }
    .cases-circle-row{
        margin-top: 8px;
    }
    .cases-circle{
        width: 132px;
        height: 132px;
    }
    .cases-circle div{
        width: 100px;
    }
    .cases-circle div p{
        font-size: 14px;
    }
    .cases-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .wide-item{
        display: none;
    }

}

@media screen and (max-width: 720px) {
    h1{
        font-size: calc(12px + 6vw);
    }
    nav{
        padding: 0.5rem 6vw;
    }
    nav .links{
        display: none;
    }
    .menu-icon{
        display: block;
    }
    .to-about-section{
        background-position: right;
        background-size: 200%;
    }
    .review-section .star-position{
        display: none;
    }
    .reviews-grid .placeholder{
        display: none;
    }
    .stats-ed{
        grid-column: span 4;
    }
    .stats-review-img{
        width: 72px;
        height: 72px;
    }
    .review-flexbox{
        flex-direction: column;
    }
    footer{
        display: flex;
        flex-direction: column;
    }
    .clients-flexbox{
        flex-direction: column;
        align-items: flex-start;
    }
    .footer-bg{
        height: 200px;
    }
    .mobile-menu{
        display: flex;
    }
    .cases-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .cases-right-border{
        border-right: none;
    }
    .ai-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    #ai-1{
        border-right: none;
    }
    .ai-grid-item{
        border-bottom: 1px solid var(--border-light);
    }
    .about-image{
        height: 380px;
    }
}

@media screen and (max-width: 510px){
    .slider-wrap{
        margin-left: 8px;
    }
    .slick-slide{
        margin-right: 8px
    }
    .h-item img{
        width: 380px;
        aspect-ratio: 1;
    }
}
